import React from "react";
//import from  @mui/material
import {
  AppBar,
  Toolbar,
  IconButton,
  CardMedia,
  Card,
  Grid,
  Button,
  Container,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
//import from  prop-types
import PropTypes from "prop-types";
import { useTheme } from "@emotion/react";

const Header = ({
  drawerWidth,
  handleDrawerToggle,
  logo,
  handleClickHome,
  userId,
  handleClickPricing,
  handleClickSignUp,
  handleClickTrial
}) => {
  const theme = useTheme();

  return (
    <AppBar
      position="fixed"
      sx={{
        // width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
        boxShadow: 0,
      }}
    >
      <Container maxWidth="xl">
        <Toolbar style={{ paddingLeft: "0px", paddingRight: "0px" }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Card
                sx={{
                  maxWidth: 345,
                  bgcolor: theme.palette.primary.main,
                  borderRadius: "0px",
                }}
              >
                <CardMedia
                  component="img"
                  height="40"
                  image={logo}
                  alt="Mperito QR Code"
                  onClick={handleClickHome}
                />
              </Card>
            </Grid>
            <Grid item>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: "none" } }}
              >
                <MenuIcon />
              </IconButton>
              {userId === false ? (
                <Grid
                  item
                  sx={{ display: { xs: "none", sm: "block", md: "block" } }}
                ></Grid>
              ) : (
                <Grid
                  item
                  sx={{ display: { xs: "none", sm: "block", md: "block" } }}
                >
                  <Grid container spacing={1}>
                    <Grid item>
                      <Button
                        variant="text"
                        disableElevation
                        onClick={handleClickHome}
                      >
                        Home
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="text"
                        disableElevation
                        onClick={handleClickTrial}
                      >
                        Try Now
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="text"
                        disableElevation
                        onClick={handleClickPricing}
                      >
                        Pricing
                      </Button>
                    </Grid>
                    {/* <Grid item>
              <Button
                variant="contained"
                onClick={handleClickSignIn}
                sx={{
                  bgcolor: theme.palette.secondary.main,
                  "&:hover": {
                    //you want this to be the same as the backgroundColor above
                    backgroundColor: theme.palette.secondary.main,
                    borderColor: theme.palette.secondary.main,
                  },
                }}
                disableElevation
              >
                Log In
              </Button>
            </Grid> */}
                    <Grid item>
                      <Button
                        variant="outlined"
                        disableElevation
                        onClick={handleClickSignUp}
                        endIcon={<ArrowRightAltIcon />}
                        sx={{
                          "&:hover": {
                            //you want this to be the same as the backgroundColor above
                            // backgroundColor: `${theme.colors?.secondaryMain}`,
                            borderColor: theme.palette.secondary.main,
                          },
                        }}
                      >
                        Sign Up / Sign In
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

Header.propTypes = {
  drawerWidth: PropTypes.number,
  handleDrawerToggle: PropTypes.func,
  logo: PropTypes.string,
  handleClickHome: PropTypes.func,
  userId: PropTypes.string,
  handleClickPricing: PropTypes.func,
  handleClickSignUp: PropTypes.func,
  handleClickTrial :PropTypes.func,
};

export default Header;
