// assets
import { IconDashboard, IconSquareRoundedPlus } from "@tabler/icons-react";
// import IconQRCodeCreate from "assets/images/icons/IconQrCreate";
// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
  id: "dashboard",
  title: "Dashboard",
  type: "group",
  children: [
    {
      id: "default",
      subtitle: "Dashboard",
      type: "item",
      url: "/dashboard/default",
      icon: <IconDashboard />,
      breadcrumbs: false,
    },
    // {
    //   id: "1",
    //   subtitle: "Create QR Code",
    //   type: "item",
    //   url: "/create-qr-code-generator",
    //   icon: <IconSquareRoundedPlus />,
    //   breadcrumbs: false,
    // },
  ],
};

export default dashboard;
