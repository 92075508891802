import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// Landing Page routing
const LandingPage = Loadable(lazy(() => import('views/landing-pages')));
const PricingPage = Loadable(lazy(() => import('views/landing-pages/Pricing')));
const TrialPage = Loadable(lazy(() => import('views/landing-pages/Trial')));
const BuySubscriptionPlanPage = Loadable(lazy(() => import('views/landing-pages/BuySubscriptionPlan')));


// ==============================|| MAIN ROUTING ||============================== //

const MinimalRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/',
      element: <LandingPage />
    },
    {
      path: '/pricing',
      element: <PricingPage />
    },
    {
      path: '/trial',
      element: <TrialPage />
    },
    {
      path: '/buy-subscription-Plan',
      element: <BuySubscriptionPlanPage />
    },
  ]
};

export default MinimalRoutes;