import {produce} from "immer"

import {
  GET_ALL_SUBSCRIPTION_PLAN,
  GET_ALL_SUBSCRIPTION_PLAN_FAILURE,
  GET_ALL_SUBSCRIPTION_PLAN_SUCCESS,
} from "../actions/subscriptionAction";

const initialState = {
  subscriptionPlans: [],
};

const subscriptionPlansReducer = (state = initialState, action) => {
  switch (action.type) {
    //qr get All Subscription Plans
    case GET_ALL_SUBSCRIPTION_PLAN: {
      return produce(state, (draft) => {
        draft.subscriptionPlans = [];
      });
    }

    case GET_ALL_SUBSCRIPTION_PLAN_SUCCESS: {
      const subscriptionPlans = action.payload;
      // console.log("GET_ALL_SUBSCRIPTION_PLAN_SUCCESS :subscriptionPlans", action.payload);
      return produce(state, (draft) => {
        draft.subscriptionPlans = subscriptionPlans;
      });
    }

    case GET_ALL_SUBSCRIPTION_PLAN_FAILURE: {
      return produce(state, (draft) => {});
    }

    default:
      // eslint-disable-next-line no-lone-blocks
      {
        return state;
      }
      break;
  }
};

export default subscriptionPlansReducer;
