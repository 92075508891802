import axios from "utils/axios";

import {
  GET_ALL_SUBSCRIPTION_PLAN_URL,
  BUY_SUBSCRIPTION_PLAN_URL,
} from "config/services";

class SubscriptionServices {
  buySubscription = (buy) =>
    new Promise((resolve, reject) => {
      axios
        .post(BUY_SUBSCRIPTION_PLAN_URL, buy)
        .then((response) => {
          if (response?.data?.error) {
            reject(response?.data?.error);
          } else {
            resolve(response?.data?.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  //get all qr code
  getAllSubscriptionPlan = () =>
    new Promise((resolve, reject) => {
      axios
        .get(GET_ALL_SUBSCRIPTION_PLAN_URL)
        .then((response) => {
          if (response?.data?.error) {
            reject(response?.data?.error);
          } else {
            resolve(response?.data);
            // console.log("getAllSubscriptionPlan :data",response?.data)
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
}
const subscriptionService = new SubscriptionServices();

export default subscriptionService;
