import { React, useEffect, useState } from "react";
import {
  Box,
  Grid,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  CssBaseline,
  Tooltip,
  IconButton,
  Avatar,
  MenuItem,
  Menu,
  ListSubheader,
  Button,
  Fab,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import MenuIcon from "@mui/icons-material/Menu";
import { Outlet, useNavigate } from "react-router-dom";

//project import
import menuItems from "menu-items";
import { useDispatch, useSelector } from "react-redux";
import { getProfile, signOut } from "actions/authActions";

import MLOGO from "assets/images/img/mperito_color_logo.png";
import { IconPlus } from "@tabler/icons-react";
import BlinkingFloatingButton from "components/BlinkingFloatingButton";
import { createNewQRCode } from "actions/qrAction";

function MainLayout(props) {
  const drawerWidth = 240;

  const theme = useTheme();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const profilePageNav = () => {
    navigate("/profile");
  };

  const createQrCodePageNav = async () => {
    await dispatch(createNewQRCode());
    navigate("/create-qr-code-generator");
  };
  const signOutNavTOHomePage = () => {
    try {
      dispatch(signOut());
      localStorage.removeItem("accessToken");
      navigate("/");
    } catch (error) {
      // enqueueSnackbar("Unable to logout", {
      // console.log("it is not sign out");
      // });
    }
  };

  const settings = [
    {
      id: 1,
      title: "Profile",
      function: profilePageNav,
    },
    {
      id: 2,
      title: "Sign Out",
      function: signOutNavTOHomePage,
    },
  ];

  const mappedMenu = menuItems.items.map((group) => {
    // Handle each group
    const groupItem = {
      id: group.id,
      title: group.title,
      type: group.type,
      children: group.children.map((child) => ({
        id: child.id,
        subtitle: child.subtitle,
        type: child.type,
        url: child.url,
        icon: child.icon, // You might want to handle icon details here
      })),
    };
    return groupItem;
  });

  //user
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  // const user = useSelector((state) => state?.auth?.user);
  // useEffect(() => {
  //   dispatch(getProfile());
  // }, [dispatch]);

  const profile = useSelector((state) => state?.auth?.user);
  //drawer
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const drawer = (
    <div>
      {/* <Toolbar /> */}
      <Grid p={2}>
        <img alt="solution" src={MLOGO} style={{ width: "100%" }} />
      </Grid>
      <Divider />
      {mappedMenu.map((item, index) => (
        <List
          key={index}
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              {item.title}
            </ListSubheader>
          }
        >
          {item.children.map((item, index) => (
            <ListItem disablePadding key={index}>
              <ListItemButton onClick={() => navigate(`${item.url}`)}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.subtitle} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      ))}
      <Divider />
    </div>
  );

  // Remove this const when copying and pasting into your project.
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
        >
          <Toolbar>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ mr: 2, display: { sm: "none" } }}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
              <Grid
                item
                sx={{ display: { xs: "content", sm: "none", md: "none" } }}
              >
                <Typography variant="h3" color="#ffffff" noWrap component="div">
                  Mperito QR Code
                </Typography>
              </Grid>

              <Grid item>
                <Tooltip title="Open settings">
                  <IconButton sx={{ p: 0 }} onClick={handleOpenUserMenu}>
                    <Avatar
                      // alt="Remy Sharp"
                      sx={{
                        bgcolor: theme.palette.secondary.main,
                        color: "#ffffff",
                      }}
                    >
                      {profile?.name?.slice(0, 1)}
                    </Avatar>
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  PaperProps={{
                    sx: { bgcolor: theme.palette.primary.main },
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting, index) => (
                    <MenuItem key={index} onClick={handleCloseUserMenu}>
                      <Button
                        sx={{ padding: "0px" }}
                        onClick={setting.function}
                        variant="text"
                        // textAlign="center"
                      >
                        {setting.title}
                      </Button>
                    </MenuItem>
                  ))}
                </Menu>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onTransitionEnd={handleDrawerTransitionEnd}
            onClose={handleDrawerClose}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>

        <Box
          component="main"
          sx={{
            p: 3.5,
            mt: 2,
            width: {
              sm: `calc(100% - ${drawerWidth}px)`,
              flexGrow: 1,
              bgcolor: "background.default",
              p: 3.5,
            },
          }}
        >
          <Toolbar />
          <Outlet />
        </Box>
      </Box>
      {/* <Fab
        variant="extended"
        size="medium"
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
          zIndex: 1000,
          bgcolor: theme.palette.secondary.main,
          color: theme.palette.secondary.light,
          "&:hover": {
            bgcolor: theme.palette.secondary.main,
            color: theme.palette.secondary.light,
          },
        }}
        onClick={createQrCodePageNav}
      >
        <IconPlus style={{ marginRight: "10px" }} />
         New QR
      </Fab> */}
      <BlinkingFloatingButton createQrCodePageNav={createQrCodePageNav} />
    </>
  );
}

export default MainLayout;
