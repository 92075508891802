import { lazy } from 'react';

// project imports
import AuthLayout from 'layout/AuthLayout';
import Loadable from 'ui-component/Loadable';


//auth routhing
const SignUpPage = Loadable(lazy(() => import('views/auth/SignUp')));
const SignInPage = Loadable(lazy(() => import('views/auth/SignIn')));
const VerifyOtpPage = Loadable(lazy(() => import('views/auth/VerifyOtp')));
const FallBackPage = Loadable(lazy(() => import('views/fall-back/FallBackPage')));
const PageNotFoundPage = Loadable(lazy(() => import('views/PageNotFound')));
// ==============================|| MAIN ROUTING ||============================== //

const AuthenticationRoutes = {
  path: '/',
  element: <AuthLayout />,
  children: [
    {
      path: '/sign-up',
      element: <SignUpPage />
    },
    {
      path: '/sign-in',
      element: <SignInPage />
    },
    {
      path: '/verify-otp',
      element: <VerifyOtpPage />
    },
    {
      path: '/fall-back',
      element: <FallBackPage />
    },
    {
      path: '/page-not-found',
      element: <PageNotFoundPage />
    },
  ]
};

export default AuthenticationRoutes;