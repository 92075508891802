import { jwtDecode } from "jwt-decode";
import axios from "utils/axios";

import {
  GET_PROFILE,
  SIGN_UP_URL,
  SEND_OTP_EMAIL_URL,
  SIGN_IN_EMAIL_VERIFY_OTP_URL,
  SIGN_OUT_URL,
  SIGN_IN_SILENT_URL,
} from "config/services";

class AuthService {
  setUserId = (userId) => {
    if (userId) {
      localStorage.setItem("userId", userId);
    } else {
      localStorage.removeItem("userId");
    }
  };

  setUserMobile = (mobile) => {
    if (mobile) {
      localStorage.setItem("mobile", mobile);
    } else {
      localStorage.removeItem("mobile");
    }
  };
  setUserEmail = (email) => {
    if (email) {
      localStorage.setItem("email", email);
    } else {
      localStorage.removeItem("email");
    }
  };

  setSession = (accessToken) => {
    console.log("setSession accessToken :",accessToken)
    if (accessToken) {
      localStorage.setItem("accessToken", accessToken);
      axios.defaults.headers.common.Authorization = `${accessToken}`;
    } else {
      localStorage.removeItem("accessToken");
      delete axios.defaults.headers.common.Authorization;
    }
  };

  getAccessToken = () => localStorage.getItem("accessToken");

  getUserId = () => localStorage.getItem("userId");

  isValidToken = (accessToken) => {
    if (!accessToken) {
      return false;
    }

    const decoded = jwtDecode(accessToken);
    // console.log(
    //   "isValidToken() accessToken: ",
    //   accessToken,
    //   " decoded",
    //   decoded
    // );
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  };

  setAxiosInterceptors = ({ onLogout }) => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          this.setSession(null);
          this.setUserId(null);

          if (onLogout) {
            onLogout();
          }
        }

        return Promise.reject(error);
      }
    );
  };

  handleAuthentication() {
    const accessToken = this.getAccessToken();

    // console.log("handleAuthentication: ", accessToken);

    if (!accessToken) {
      return;
    }

    if (this.isValidToken(accessToken)) {
      this.setSession(accessToken);
    } else {
      this.setSession(null);
      this.setUserId(null);
    }
  }

  isAuthenticated = () => !!this.getAccessToken();

  signUp = (newUser) =>
    new Promise((resolve, reject) => {
      axios
        .post(SIGN_UP_URL, newUser)
        .then((response) => {
          if (response?.data?.error) {
            reject(response?.data?.error);
          } else {
            const payload = response?.data?.data;
            if (payload) {
              this.setSession(payload?.token);
              this.setUserId(payload?.user?.userId);
              this.setUserEmail(payload?.user?.email);
              this.setUserMobile(payload?.user?.mobile);
            }
            resolve(payload?.user, response?.data);
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data);
          }
          reject(error);
        });
    });

  sendOtpOnEmail = (email) =>
    new Promise((resolve, reject) => {
      axios
        .post(SEND_OTP_EMAIL_URL, email)
        .then((response) => {
          // console.log("authService:sendOtpOnEmail: Response", response.data);
          if (response.data.error) {
            // console.log(
            //   "authService:sendOtpOnEmail:Error1",
            //   response.error,
            //   response.data
            // );
            reject(response.data.message);
          } else {
            resolve(response.data.message);
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data);
          }

          reject(error);
        });
    });

  signInEmailVerifyOTP = (email, otp) =>
    new Promise((resolve, reject) => {
      axios
        .post(SIGN_IN_EMAIL_VERIFY_OTP_URL, { email, otp })
        .then((response) => {
          if (response?.data?.error) {
            reject(
              {
                message: response?.data?.message,
                data: response?.data,
                error: response?.data?.error,
                errorCode: response?.data?.errorCode,
              },
              response?.data
            );
          } else {
            const payload = response?.data?.data;
            if (payload) {
              this.setSession(payload?.token);
              this.setUserId(payload?.user?.userId);
              this.setUserEmail(payload?.user?.email);
              this.setUserMobile(payload?.user?.mobile);
            }
            // resolve(user);
            resolve(payload?.user);
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data);
          }
          reject(error);
        });
    });

  signOut = () => {
    new Promise((resolve, reject) => {
      // this.setSession(null);
      // this.setUserId(null);
      axios
        .post(SIGN_OUT_URL)
        .then((response) => {
          // this.setUserEmail(null);
          // this.setUserMobile(null);
          // console.log(
          //   "authService:signinWithEmailAndPassword: Response",
          //   response.data
          // );
          resolve();
        })
        .catch((error) => {
          if (error.response) {
            // console.log(
            //   "authService:signout:Error2",
            //   error
            // );
            // reject(error?.response?.data);
            resolve();
          }
          reject(error);
        });
    });
  };

  getProfile = () =>
    new Promise((resolve, reject) => {
      axios
        .get(GET_PROFILE)
        .then((response) => {
          if (response?.data?.error) {
            reject(response?.data?.error);
          } else {
            resolve(response?.data);
            // console.log("qrGeneratePreview :preview",response?.data)
          }
        })
        .catch((error) => {
          if (error.response) {
            resolve();
          }
          reject(error);
        });
    });

  loginInWithToken = (userId) =>
    new Promise((resolve, reject) => {
      // console.log("loginInWithToken: ",userId, this.getAccessToken());
      // const getAccessToken = localStorage.getItem("accessToken");
      axios
        .get(SIGN_IN_SILENT_URL)
        .then((response) => {
          if (response?.data?.error) {
            reject({ message: response?.data?.message });
          } else {
            const payload = response?.data?.data;
            if (payload) {
              this.setUserId(payload?.user?.userId);
              this.setUserEmail(payload?.user?.email);
              this.setUserMobile(payload?.user?.mobile);
            }
            // resolve(user);
            resolve(payload?.user);
          }
        })
        .catch((error) => {
          //console.log("loginInWithToken->error" + JSON.stringify(error));
          reject(error);
          resolve();
        });
    });
}

const authService = new AuthService();

export default authService;
