import NewSubscriptionPlan from "interface/subscriptionPlan";
import subscriptionService from "services/subscriptionService";

export const BUY_SUBSCRIPTION_PLAN = "@subscription/buy-subscription";
export const BUY_SUBSCRIPTION_PLAN_SUCCESS = "@subscription/buy-subscription-success";
export const BUY_SUBSCRIPTION_PLAN_FAILURE = "@subscription/buy-subscription-failure";

export const GET_ALL_SUBSCRIPTION_PLAN = "@subscription/get-all";
export const GET_ALL_SUBSCRIPTION_PLAN_SUCCESS = "@subscription/get-all-success";
export const GET_ALL_SUBSCRIPTION_PLAN_FAILURE = "@subscription/get-all-failure";

export function buySubscription(buy) {
    return async (dispatch) => {
      const newQrInfo = new NewSubscriptionPlan(buy);
      try {
        dispatch({
          type: BUY_SUBSCRIPTION_PLAN,
          payload: {
            newQrInfo,
          },
        });
        // console.log("AuthAction.signupMobile:", name, mobile);
        const user = await subscriptionService.buySubscription(newQrInfo);
        // console.log("qrGenerate.user: Resp:" , user);
        dispatch({
          type: BUY_SUBSCRIPTION_PLAN_SUCCESS,
          payload: {
            user,
          },
        });
      } catch (error) {
        // console.log("AuthAction.signupMobile: Error", error);
        dispatch({ type: BUY_SUBSCRIPTION_PLAN_FAILURE });
        throw error;
      }
    };
  }

  

  export function getAllSubscriptionPlans() {
    return async (dispatch) => {
      try {
        dispatch({
          type: GET_ALL_SUBSCRIPTION_PLAN,
        });
        const subscribe = await subscriptionService.getAllSubscriptionPlan();
        dispatch({
          type: GET_ALL_SUBSCRIPTION_PLAN_SUCCESS,
          payload: subscribe,
          
        });
      } catch (error) {
        dispatch({ type: GET_ALL_SUBSCRIPTION_PLAN_FAILURE });
        throw error;
      }
    };
  }
